.game-div {
    display: flex;
    flex-direction: row;
    /* padding: 30px; */
}

.game-div-game-board {
    display: flex;
    flex-direction: row;
    /* align-content: center; */
    align-items: center;
    /* justify-content: center; */
    /* justify-items: center; */

    height: 100vh;
    width: 750px;

    /* background-image: url("../../images/Blank_Go_Board.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 2%;
    width: 60%;
    height: 100vh; */
}

/* .game-div-game-board-image {
    background-image: url("../../images/Blank_Go_Board.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 20px;
    width: 100%;
    height: 100%;
} */

.game-section {
    background-image: url("../../images/Blank_Go_Board.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* padding: 2%; */
    width: 710px;
    height: 700px;
    /* background-color: red; */
    /* width: 100%;
    height: 30%; */
    padding: 22px 28px;
    padding-right: 0px;
}

.game-div-clickable-board {
    /* display: inline-grid;
    grid-template-columns: repeat(19, 15%);
    background-color: black; */
    display: flex;
    flex-wrap: wrap;
    margin-left: -13px;
    margin-top: -3px;
    width: 105%;
}

.game-div-clickable-board-go-piece {
    background-color: black;
    border-radius: 50%;
    /* width: 50px; */
    /* z-index: 1; */
    /* margin: 5px; */
    /* width: 600;
    height: 600; */
    /* padding: 15px; */
    /* margin-left: -10px; */
    /* margin-top: -10px; */
    margin-bottom: 18px;
    margin-right: 18px;
    width: 20px;
    height: 20px;
}

.game-right-side-container {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    position: relative;
    top: -20px;
}