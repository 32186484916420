.game-div-content-container {
    display: flex;
    flex-direction: row;
    padding: 30px;
}

.chat-box-container-div {
    /* margin-left: 30px; */
    margin-top: 30px;
}

.chat-box {
    /* position: absolute;
    bottom: 0px;
    right: 0px; */
    width: 400px;
    height: 430px;
    border: 1px solid lightgray;
    border-radius: 5px;
    
    background-color: #F5F5F5;
}

.chat-box-chat-section {
    padding-top: 10px;
    padding-left: 15px;
    height: 369px;
    overflow: scroll;
    /* overflow: scroll; */
}

.chat-box-nav-bar {
    color: white;
    background-color: #03A9F4;
    border-radius: 3px;
}

.chat-box-nav-bar-button {
    width: 30px;
    padding-left: 20px;
    fill: white;
    cursor: pointer;
}

.chat-input-div {
    /* position: absolute;
    bottom: 0px;
    left: 0px; */
    width: 100%;
    display: flex;
    border-top: 1px solid lightgray;
    height: 49px;
}

.chat-input-div-input {
    /* padding: 10px; */
    border-radius: 5px;
    padding: 10px;
    width: 90%;
    background-color:rgb(230, 230, 230);
}

.chat-input-div-input::placeholder {
    font-size: 14px;
}

.chat-input-div-send-button {
    width: 10%;
    align-self: center;
    padding: 8px;
    color: #03A9F4;
    /* justify-self: flex-end; */
}

.chat-input-div-send-button:hover {
    color: #038BC8;
}

.chat-box-message {
    /* padding-left: 8px; */
}

.message-div {
    padding-bottom: 10px;
}

/* .minus-icon {
    fill: white;
}

.minus-icon:hover {
    fill: green;
} */