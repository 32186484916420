.splash-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.splash-page-create-lobby-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* padding: 20px; */
    background-color: rgb(250, 250, 250);

    width: 100%;

    border-bottom: 1px solid lightgray;
    padding: 20px;
}

.splash-page-app-title {
    padding-top: 20px;
    font-size: 64px;
}

.splash-page-app-sub-title {
    color: darkgray;
}

#splash-page-create-lobby-button {
    margin-top: 20px;
    margin-bottom: 20px;
}

.lobby-row {
    display: flex;
    padding: 20px;
    background-color: rgb(245, 245, 245);
    border-bottom: 1px solid lightgray;

    transition: border 0.1s, box-shadow 0.3s, transform 0.3s, transform 0.3s, fill 0.3s;
}

.lobby-row:hover {
    border: 1px solid #949494;
    box-shadow: 0 2px 6px -4px rgba(0,0,0,.2), 0 8px 10px -4px rgba(0,0,0,.14), 0 8px 12px 4px rgba(0,0,0,.05);
    transform: translateY(-2px);
    fill: rgb(80, 181, 109);
}