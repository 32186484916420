.lobby-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 100vh; */
    justify-content: center;
}

.lobby-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 450px;
    background: rgb(230, 230, 230);
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    width: 100vw;
    
}

.lobby-row-title {
    padding: 20px;
    width: 200px;
    overflow: hidden;
    padding-right: 20px;
}

.lobby-row-right-items {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding-right: 30px;
}

#f-page-join-lobby-button {

}

.edit-game-input-text {
    border-bottom: 1px solid lightgray;
    background-color: rgb(245, 245, 245);
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
}

#lobby-multi-go-sub-title {
    padding-bottom: 45px;
}

.lobby-edit-game-title {
    padding: 15px;
    /* padding-left: 0px; */
    text-align: center;
}

#lobby-save-settings-button {
    padding: 10px;
    text-align: center;
    margin: 20px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 35px;
}

#lobby-start-game-button {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 40px;
}

.lobby-players {
    margin-top: 20px;
}

.lobby-players-player {
    display: flex;
    padding: 15px;
    border: 1px solid darkgray;
    width: 100% - 30px;
}

.lobby-go-piece-image {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.lobby-go-player-color {
    margin-right: 5px;
}

.game-settings-white-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 350px;
    width: 352px;
}

.game-settings-content-container {
    /* background-color: blue; */
    width: 250px;
}